/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Adlam+Unjoined:wght@400..700&display=swap");

/* CSS Variables */
:root {
  --primary-color: #4a4a4a;
  --secondary-color: #3677bf;
  --background-color: #f9f9f9;
  --card-background-color: #ffffff;
  --button-background-color: #3677bf;
  --button-text-color: #ffffff;
  --input-background-color: #ffffff;
  --input-border-color: #dcdcdc;
  --highlight-color: #ffcccc;
  --label-color: #8b96aa;
  --value-color: #4a4a4a;
  --gradient-start: #bfdffe;
  --gradient-mid: #cceaff;
  --gradient-end: #ffcbdc;
  --hover-color: #669acc;
  --button-color: #3677bf;
  --background-secondary: #f0f0f0;
  --text-secondary: #6b7280;
  --text-primary: #333333;
  --primary-color-dark: #285e8e;
}

/* Keyframes for Background Animation */
@keyframes liquidFlow1 {
  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

@keyframes liquidFlow2 {
  0%,
  100% {
    background-position: 100% 50%;
  }

  50% {
    background-position: 0% 50%;
  }
}

@keyframes liquidFlow3 {
  0%,
  100% {
    background-position: 50% 0%;
  }

  50% {
    background-position: 50% 100%;
  }
}

@keyframes liquidFlow4 {
  0%,
  100% {
    background-position: 50% 100%;
  }

  50% {
    background-position: 50% 0%;
  }
}

/* Global Styles */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: radial-gradient(circle at 30% 30%, var(--gradient-start) 0%, transparent 50%),
    radial-gradient(circle at 70% 70%, var(--gradient-end) 0%, transparent 50%),
    radial-gradient(circle at 50% 50%, var(--gradient-mid) 0%, transparent 60%),
    radial-gradient(ellipse at 20% 80%, var(--gradient-start) 0%, transparent 70%),
    radial-gradient(ellipse at 80% 20%, var(--gradient-end) 0%, transparent 70%),
    radial-gradient(circle at 40% 60%, #c4e0ff 0%, transparent 40%),
    radial-gradient(circle at 60% 40%, #ffe6e6 0%, transparent 40%),
    linear-gradient(
      45deg,
      var(--gradient-start) 0%,
      var(--gradient-mid) 25%,
      #d9e8ff 50%,
      var(--gradient-mid) 75%,
      var(--gradient-end) 100%
    );
  background-size: 400% 400%, 400% 400%, 200% 200%, 300% 300%, 300% 300%,
    200% 200%, 200% 200%, 200% 200%;
  background-attachment: fixed;
  animation: liquidFlow1 20s ease infinite, liquidFlow2 25s ease infinite,
    liquidFlow3 30s ease infinite, liquidFlow4 35s ease infinite;
  color: var(--primary-color);
  font-family: "Noto Sans Adlam Unjoined", sans-serif;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--value-color);
  font-family: "Noto Sans Adlam Unjoined", sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  color: var(--hover-color);
}

button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-family: "Noto Sans Adlam Unjoined", sans-serif;
  margin-left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: var(--hover-color);
}

button:disabled {
  background-color: #8b96aa;
  cursor: not-allowed;
}

input[type="text"],
input[type="number"],
input[type="email"],
textarea {
  background-color: var(--input-background-color);
  width: 100%;
  font-family: "Noto Sans Adlam Unjoined", sans-serif;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  border: 1px solid var(--input-border-color);
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  appearance: none;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gradient-text {
  background: linear-gradient(
    135deg,
    var(--gradient-start) 0%,
    var(--gradient-end) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat", sans-serif;
}

/* Main Content Wrapper */
.main-content {
  flex: 1;
  /* position: sticky;
  top: 0; */
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
}

.header-logo {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-logo img.logo-image {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.header-logo h2 {
  font-size: 24px;
  font-weight: 700;
}

.wallet-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  width: 100%;
}

.wallet-info p {
  margin: 0;
  font-family: "Noto Sans Adlam Unjoined", sans-serif;
  background: var(--input-background-color);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.connectWalletButton {
  width: 160px;
  height: 40px;
  border-radius: 10px;
  background-color: #3677bf;
  padding: 9px 20px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.wrongNetworkButton {
  background-color: red;
  width: 160px;
  height: 40px;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.walletConnectButton {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
}

.openModalButton {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 160px;
  height: 40px;
  justify-content: center;
}

.walletbalance {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.icon-nav {
  width: 20px;
  height: 20px;
  position: relative;
  top: 1px;
}

.icon-spacing {
  margin-right: 5px;
}

.small-icon {
  font-size: 8px;
}

.large-icon {
  font-size: 20px;
}
.link-container {
  display: flex;
  /* align-items: center;  */
}

.submitter-container {
  display: flex;
  align-items: center;
}

.submitter-id {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.walletbalance span {
  font-size: 16px;
  font-weight: 400;
  color: var(--value-color);
  white-space: nowrap;
}

.walletbalance .tokenBalance {
  color: #2d3992;
  font-size: 16px;
  font-weight: 400;
}

/* Footer */
.footer {
  background: none;
  text-align: center;
  font-size: 12px;
  color: var(--primary-color);
  background-color: #ffffff;
  width: 100%;
  padding: 10px 0px;
  margin-top: auto;
}

.footer .footer-menu-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
}

.footer .footer-menu-section span {
  color: var(--value-color);
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.footer img {
  width: 30px;
  height: 30px;
}

.footer .footer-logo-section {
  display: flex;
  flex-direction: column;
}

.footer .footer-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.footer .footer-img p {
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.footer a {
  color: var(--secondary-color);
  text-decoration: none;
  margin: 0 10px;
  font-weight: 400;
  font-size: 14px;
}

.footer a:hover {
  color: var(--highlight-color);
}

.footer a:last-child {
  margin-right: 0px;
  /* Remove bottom margin from the last button */
}

.footer .footer-nav-menu {
  display: flex;
  gap: 10px 0;
}

.footer span {
  font-weight: 400;
  font-size: 14px;
  color: #8b96aa;
}

/* Error Message */

.error-message p {
  background-color: var(--highlight-color);
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 20px;
}

.notice-message {
  color: #ff4747 !important;
  font-weight: 400;
  font-size: 14px;
}
.sub-tabs{
  display: flex;
}
/* Tabs */
.tabs {
  display: flex;
  margin-bottom: 20px;
  gap: 8px;
  display: grid;
  grid-template-columns: auto auto auto auto;

  /* background-color: black ; */
}


.tab {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  color: #6b7280;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.tab.active {
  color: black;
  background-color: rgb(151, 178, 248);
  font-weight: 600;
  border-bottom: 2px solid #3b82f6;
}

.tab:hover {
  background-color: var(--hover-color);
  color: white;
}

.tab.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  color: var(--button-text-color);
  font-weight: 600;
  opacity: 0.5;
}

.tab.disabled:hover {
  color: var(--button-text-color);
  background-color: #8b96aa;
}

.icon {
  width: 18px !important;
  height: 18px !important;
}

/* Mining Content */
.mining-content {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.content {
  margin-top: 20px;
}

/* Session Item */
.session-item {
  background: white;
  border-radius: 8px;
  padding: 16px;
  margin-top: 20px;
  margin-bottom: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Session Header */
.session-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 8px;
}

.session-header h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

.session-header strong {
  font-size: 0.8rem;
}

.badge {
  position: absolute;
  top: -12px;
  left: 12px;
  padding: 0.5em 0.5em;
  background-color: #ced0d1;
  border-radius: 20px;
}
.session-header p {
  margin: 0;
  font-size: 0.7rem;
}

/* Two-Column Grid */
.two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

/* Columns */
.column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.leaderboard-container {
  background: #f8f9fa;
  /* padding: 1rem; */
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.leaderboard-table {
  border-spacing: 20px 20px;
}

.leaderboard-table thead td {
  font-weight: 600;
  white-space: nowrap;
}

.leaderboard-table tbody td {
  text-align: right;
}

.leaderboard-table tbody td:nth-child(1),
.leaderboard-table tbody td:nth-child(2) {
  text-align: left;
}

/* Info Group */
.info-group {
  border-radius: 0.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
}

.info-group h5 {
  margin-bottom: 1rem;
  background: #f8f9fa;
  padding-left: 1rem;
  padding-top: 1rem;
  color: #333;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
  border-radius: 8px 8px 0 0;
}

.info-group p {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #707070;
}

.inner-card {
  padding-left: 1rem !important;
  padding-right: 1rem;
}

.info-group ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0.5rem;
  color: #707070;
  /* padding-left: 1rem; */
}

.info-group strong {
  color: #303030 !important;
}

.info-group ul li {
  margin-bottom: 0.5rem;
  word-break: break-all;
  padding-left: 0;
  color: #707070;
  /* padding-left: 1rem; */
}

.challenge-list ul {
  list-style-type: decimal;
  padding-left: 1.5rem;
  color: #707070;
  /* padding-right: 1rem; */
}

.completions-list ul li {
  background: rgb(240, 239, 239);
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  color: #707070;
}

.completions-list small {
  display: block;
  color: #6c757d;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.output-display {
  padding-left: 0 !important;
}

.info-group strong {
  color: #495057;
}

/* Refresh Section */
.refresh-section {
  background-color: var(--background-secondary);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  /* position: sticky;
  top: 70px; */
  transition: border-radius 0.3s ease;
  z-index: 2;
  /* background-color: black; */
}

.refresh-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.session-info {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.session-info p {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.session-lookup {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
  max-width: 400px;
}

input.session-input {
  padding: 0;
  border: 0;
  outline: none;
  font-size: 1rem;
}

.session-input::placeholder {
  font-weight: 400;
}

/* Pagination Buttons */
.pagination-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.pagination-btn {
  padding: 0.4rem 0.8rem;
  border: none;
  cursor: pointer;
  min-width: 80px;
  text-align: center;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: var(--button-background-color);
  font-size: 14px;
  /* position: relative; */
}

.pagination-btn.hover {
  background-color: var(--hover-color);
}

.pagination-btn-new {
  all: unset;
  border: none;
  cursor: pointer;
  text-align: center;
  color: var(--button-background-color);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: transparent;
  font-size: 16px;
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
  transition: none;
  display: flex;
}

.pagination-btn-new i {
  padding: 0 4px;
}

.pagination-session-id {
  color: var(--button-background-color);
}

.pagination-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-btn-new:disabled {
  background-color: transparent;
  color: rgb(182, 179, 179);
  cursor: not-allowed;
}

.pagination-btn-new:hover:not(:disabled) {
  background-color: transparent;
  color: var(--primary-color-dark);
}

.pagination-btn:hover:not(:disabled) {
  background-color: var(--hover-color);
}

/* Refresh Button */
.refresh-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding: 0.4rem 0.8rem;
  border-radius: 0.375rem;
  background-color: #3677bf;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
  font-size: 14px;
  height: auto;
}

.refresh-button .icon {
  width: 16px;
  height: 16px;
}

.refresh-button:hover:not(:disabled) {
  background-color: var(--primary-color-dark);
}

.refresh-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Loading */
div.spinner {
  position: relative;
  width: 54px;
  height: 54px;
  display: inline-block;
  margin-left: 45%;
  background: white;
  box-shadow: 0px 0px 16px #dde2eb;
  padding: 10px;
  border-radius: 10px;
}

div.spinner div {
  width: 6%;
  height: 16%;
  background: rgb(191, 223, 254);
  background: -moz-linear-gradient(
    132deg,
    rgba(191, 223, 254, 1) 25%,
    rgba(255, 203, 220, 1) 100%
  );
  background: -webkit-linear-gradient(
    132deg,
    rgba(191, 223, 254, 1) 25%,
    rgba(255, 203, 220, 1) 100%
  );
  background: linear-gradient(
    132deg,
    rgba(191, 223, 254, 1) 25%,
    rgba(255, 203, 220, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bfdffe",endColorstr="#ffcbdc",GradientType=1);
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  -webkit-border-radius: 50px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-animation: fade 1s linear infinite;
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
div.spinner div.bar1 {
  -webkit-transform: rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
}

div.spinner div.bar2 {
  -webkit-transform: rotate(30deg) translate(0, -130%);
  -webkit-animation-delay: -0.9167s;
}

div.spinner div.bar3 {
  -webkit-transform: rotate(60deg) translate(0, -130%);
  -webkit-animation-delay: -0.833s;
}
div.spinner div.bar4 {
  -webkit-transform: rotate(90deg) translate(0, -130%);
  -webkit-animation-delay: -0.7497s;
}
div.spinner div.bar5 {
  -webkit-transform: rotate(120deg) translate(0, -130%);
  -webkit-animation-delay: -0.667s;
}
div.spinner div.bar6 {
  -webkit-transform: rotate(150deg) translate(0, -130%);
  -webkit-animation-delay: -0.5837s;
}
div.spinner div.bar7 {
  -webkit-transform: rotate(180deg) translate(0, -130%);
  -webkit-animation-delay: -0.5s;
}
div.spinner div.bar8 {
  -webkit-transform: rotate(210deg) translate(0, -130%);
  -webkit-animation-delay: -0.4167s;
}
div.spinner div.bar9 {
  -webkit-transform: rotate(240deg) translate(0, -130%);
  -webkit-animation-delay: -0.333s;
}
div.spinner div.bar10 {
  -webkit-transform: rotate(270deg) translate(0, -130%);
  -webkit-animation-delay: -0.2497s;
}
div.spinner div.bar11 {
  -webkit-transform: rotate(300deg) translate(0, -130%);
  -webkit-animation-delay: -0.167s;
}
div.spinner div.bar12 {
  -webkit-transform: rotate(330deg) translate(0, -130%);
  -webkit-animation-delay: -0.0833s;
}
/* Node Stats Container */
.node-stats-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 16px 0;
}

.node-stat-row {
  background: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.node-stat-row:hover {
  box-shadow: 0 8px 16px #0003;
  transform: translateY(-5px);
}

.node-stat-row h4 {
  color: #333;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.node-stat-row p {
  margin: 5px 0;
  font-size: 14px;
}

/* Address Display */
.address-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

}
.metric-display {
  display: flex;
  background: #f8f9fa;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 !important;
  flex-direction: column;
}


.current-address p {
  margin: 0;
  font-size: 0.9rem;
  color: #495057;
  font-family: monospace;
}

.address-lookup {
  display: flex;
  gap: 0.5rem;
  flex-grow: 1;
  max-width: 600px;
  margin-top: 0px;
}

.address-search {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
  max-width: 400px;
}

input.address-input {
  padding: 0;
  border: 0;
  outline: none;
  font-size: 1rem;
}

.lookup-button {
  flex-grow: 0;
  height: 40px;
  padding: 0 1rem;
  background: #3677bf;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  text-align: center;
}

.lookup-button:hover:not(:disabled) {
  background: #0b5ed7;
}

.lookup-button:disabled {
  background: #6c757d;
  cursor: not-allowed;
  opacity: 0.65;
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 600px;
}

.empty-state-content {
  margin-bottom: 2rem;
}

.empty-state-content .icon {
  color: #6c757d;
  margin-bottom: 1rem;
}

.empty-state-content h3 {
  margin-bottom: 1rem;
  color: #343a40;
}

.empty-state-content p {
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.no-stats-message {
  text-align: center;
  padding: 2rem;
  color: #6c757d;
  font-style: italic;
}

/* Pagination Info */
.pagination-info {
  text-align: center;
  margin-top: 1rem;
  font-size: 14px;
  color: #6b7280;
}

/* Modal Overlay */
.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.modal-content h2 {
  margin-top: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.modal-content p {
  margin-bottom: 1.5rem;
}
.ping-chart-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
  height: 100%;
}
.chart-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
}

.time-interval-table {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
}

.no-data-card {
  background-color: white;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust height as needed */
}

.no-data-text {
  text-align: center;
  font-size: 16px; /* Adjust font size as needed */
}
.header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.back-arrow {
  cursor: pointer;
  margin-right: 10px;
  margin-top: 6px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.popup-content button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background-color: var(--button-background-color);
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.popup-content button:hover {
  background-color: var(--hover-color);
}

.back-and-metrics {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.count-display {
  display: flex;
  gap: 1rem; /* Space between counts */
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
}

.count-display p {
  margin: 0;
}

.count-display span {
  font-weight: bold; /* Highlight color for counts */
}

/* Media Queries */

/* Tablets and Smaller Screens */
@media (max-width: 1024px) {
  .two-column-grid {
    grid-template-columns: 1fr;
  }

  .node-stats-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .session-header {
    align-items: flex-start;
  }

  .session-header h4 {
    font-size: 1.25rem;
  }

  .session-header p {
    font-size: 1rem;
  }

  .session-info {
    justify-content: space-between;
  }

  /* Add container padding control */
  .mining-content {
    padding: 10px;
  }

  /* Control info-group width */
  .info-group {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    word-break: break-word;
  }

  .refresh-content {
    display: block;
  }

  .pagination-buttons {
    margin-top: 1rem;
    justify-content: space-between;
  }

  .pagination-btn {
    flex: 1;
  }

  .btn.pagination-btn {
    justify-items: center;
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .node-stats-container {
    grid-template-columns: 1fr;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .header-logo h2 {
    font-size: 1.5rem;
  }

  .wallet-info .connectWalletButton {
    width: 100%;
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .tab {
    flex: 1 1 100%;
  }

  .session-info {
    width: 100%;
  }

  .address-display {
    flex-direction: column;
    align-items: flex-start;
  }

  .address-lookup {
    flex-direction: column;
    width: 100%;
  }

  .address-input,
  .lookup-button {
    width: 100%;
  }

  .pagination-buttons {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    gap: 0.5rem;
  }

  .pagination-btn,
  .refresh-button {
    width: 100%;
  }

  .refresh-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-logo-section {
    align-items: center;
  }

  .footer-menu-section {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .footer-nav-menu {
    flex-wrap: wrap;
    justify-content: center;
  }

  /* Adjust container padding */
  .mining-content {
    padding: 8px;
  }

  /* Further control info-group on mobile */
  .info-group {
    padding: 12px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  /* Control text overflow in info-group */
  .info-group p,
  .info-group ul li {
    word-break: break-word;
    overflow-wrap: break-word;
  }

  /* Adjust column layout */
  .column {
    padding: 0;
    width: 100%;
  }

  /* Session item adjustments */
  .session-item {
    padding: 12px;
    margin: 8px 0;
    width: 100%;
    box-sizing: border-box;
  }

  .btn.pagination-btn {
    justify-items: center;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .wallet-info {
    justify-content: right;
    width: 100%;
  }

  .wallet-info .connectWalletButton {
    width: 100%;
  }

  .wallet-info .walletConnectButton {
    width: 100%;
  }

  .walletbalance {
    flex-direction: column;
    align-items: center;
  }

  .walletbalance span {
    text-align: center;
  }

  .session-info {
    display: block;
  }

  .session-lookup {
    margin-top: 10px;
    max-width: 400px;
  }

  .header-logo {
    justify-content: center;
    width: 100%;
  }

  .header-logo h2 {
    text-align: center;
    font-size: 1.2rem;
  }

  .footer-menu-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-nav-menu {
    flex-direction: column;
    align-items: center;
  }

  .footer a {
    margin: 5px;
  }

  /* Further reduce padding */
  .mining-content {
    padding: 5px;
  }

  .info-group {
    padding: 10px;
  }

  /* Adjust font sizes for better readability */
  .info-group h5 {
    font-size: 1rem;
  }

  .info-group p,
  .info-group ul li {
    font-size: 0.9rem;
  }

  .pagination-container {
    margin-top: 1rem;
    justify-content: center;
  }

  .btn.pagination-btn {
    justify-items: center;
  }
}

/* Very Small Screens */
@media (max-width: 400px) {
  .node-stats-container {
    grid-template-columns: 1fr;
    padding: 8px;
  }

  .session-header h4 {
    font-size: 1rem;
  }

  .info-group h5 {
    font-size: 1rem;
  }

  .pagination-btn {
    padding: 8px;
    font-size: 14px;
  }

  .wallet-info {
    padding: 5px 0;
  }

  .mining-content {
    padding: 4px;
  }

  .session-item,
  .info-group {
    padding: 8px;
    margin: 4px 0;
  }

  /* Ensure no horizontal scrolling */
  .info-group,
  .session-item,
  .column {
    max-width: 100vw;
    overflow-x: hidden;
  }

  .pagination-container {
    margin-top: 1rem;
    justify-content: center;
  }

  .btn.pagination-btn {
    justify-items: center;
  }
}

div:has(> .table-stats) {
  align-items: center;
  flex-wrap: wrap;
}

table th {
  vertical-align: middle !important;
}

.leaderboard-container button {
  box-shadow: none;
}

.share-button {
  z-index: 999;
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #00000080;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.share-button img {
  width: 20px;
  height: 20px;
}

.share-button span {
  font-size: 12px;
  color: #000000;
  width: 80px;
  text-align: center;
}

.miner-link {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.miner-link img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.share-btn {
  display: flex;
  vertical-align: middle !important;
  background-color: var(--text-secondary);
}

.share-btn:hover {
  background-color: var(--text-primary) !important;
}

.share-btn {
  display: flex;
  vertical-align: middle !important;
  background-color: var(--text-secondary);
}

.share-btn:hover {
  background-color: var(--text-primary) !important;
}

.link-button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.link-button:hover {
  background-color: var(--hover-color);
  color: white !important;
}

.custom-header-cell {
  padding-left: 17px !important;
}

.MuiList-root.MuiMenu-list {
  padding: 10px 5px !important;
  width: 100% !important;
  margin-right: -5px;
}

.MuiInputBase-root .MuiInputBase-input {
  border: none;
  outline: none;
  background: none;
}

.MuiButtonBase-root.MuiButton-root:hover {
  color: white;
}

.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: white !important;
}

.Mui-TableHeadCell-Content-Wrapper.MuiBox-root.css-lapokc {
  white-space: pre-line !important;
}

.tab-active-updated {
  background: #669acc !important;
  color: #fff !important;
}
.sub-tab-updated{
  background-color: #efefef;
  box-shadow: none;
  border-radius: 0;
}
.tab-updated {
  background-color: white;
  box-shadow: none;
}

.tab-disabled-updated {
  background: #bec5d3 !important;
  opacity: 1 !important;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.small-icon {
  font-size: 8px;
}

.large-icon {
  font-size: 20px;
}

.link-icon {
  color: black;
}

.italic-font {
  font-style: italic;
}

.show-dash {
  margin-left: 10px;
}

.Mui-TableHeadCell-Content-Wrapper.MuiBox-root.css-lapokc {
  white-space: pre-line !important;
}

.MuiFormControl-root.MuiTextField-root {
  max-width: 230px;
}

.copy-btn {
  margin-left: 5px;
  cursor: pointer;
  stoke-width: 1.5px !important;
  width: 18px;
  height: 18px;
}

.copy-btn:hover {
  color: var(--text-primary);
}

.status-branding {
  background: rgb(191, 223, 254);
  background: -moz-linear-gradient(
    86deg,
    rgba(191, 223, 254, 1) 0%,
    rgba(204, 234, 255, 1) 50%,
    rgba(255, 203, 220, 1) 100%
  );
  background: -webkit-linear-gradient(
    86deg,
    rgba(191, 223, 254, 1) 0%,
    rgba(204, 234, 255, 1) 50%,
    rgba(255, 203, 220, 1) 100%
  );
  background: linear-gradient(
    86deg,
    rgba(191, 223, 254, 1) 0%,
    rgba(204, 234, 255, 1) 50%,
    rgba(255, 203, 220, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bfdffe",endColorstr="#ffcbdc",GradientType=1);
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 13px !important;
}

.m-t-b-16 {
  margin-top: 16px;
  margin-bottom: 10px;
}
